import { SuperModel } from './super-model.model';
import { NutritionData } from './food.model';

export class Consumption extends SuperModel {
  water: number;
  fruits: number;
  alcohol: number;
  phisycalActivityKiloCalories: number;
  nutritionData: NutritionData;

  get serialised() {
    return {
      water: this.water,
      fruits: this.fruits,
      alcohol: this.alcohol,
      phisycalActivityKiloCalories: this.phisycalActivityKiloCalories,
      nutritionData: this.nutritionData.serialised,
    };
  }
}
