import { SuperModel } from './super-model.model';

export class NeedModel extends SuperModel {
  name: string;
  description: string;

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
    };
  }
}
